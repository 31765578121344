import { faHeading, faListOl, faListUl, faParagraph, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import { Menu } from '@base-ui-components/react/menu';
import { $createParagraphNode, $getSelection, $isRangeSelection, LexicalEditor } from 'lexical';
import React from 'react';
import { dropdown, dropdownItem, DropdownItemActive, DropdownItemIcon, DropdownItemText } from './Dropdown';
import { toolbarItemButtonRecipe, toolbarItemFormat } from './toolbar.css';
import { SupportedBlockType } from './utils';

const blockTypeToIcon = {
  h1: faHeading,
  h2: faHeading,
  h3: faHeading,
  h4: faHeading,
  h5: faHeading,
  ol: faListOl,
  ul: faListUl,
  paragraph: faParagraph,
  quote: faQuoteRight,
};

const BlockOptionsToolbar = ({ editor, blockType }: { editor: LexicalEditor; blockType: SupportedBlockType }) => {
  const formatParagraph = () => {
    console.log('formatParagraph');
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatLargeHeading = () => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h2'));
        }
      });
    } else {
      formatParagraph();
    }
  };

  const formatSmallHeading = () => {
    if (blockType !== 'h3') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h3'));
        }
      });
    } else {
      formatParagraph();
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    } else {
      formatParagraph();
    }
  };

  return (
    <Menu.Root modal={false}>
      <div>
        <Menu.Trigger
          className={toolbarItemButtonRecipe({ active: ['h2', 'h3'].includes(blockType), spaced: true })}
          aria-label="Formatting Options"
        >
          <FontAwesomeIcon
            className={toolbarItemFormat}
            icon={['h2', 'h3'].includes(blockType) ? blockTypeToIcon[blockType] : faParagraph}
            fixedWidth
          />
        </Menu.Trigger>
        <Menu.Portal>
          <Menu.Positioner positionMethod="fixed">
            <Menu.Popup className={dropdown}>
              <Menu.Item className={dropdownItem} onClick={formatParagraph}>
                <DropdownItemIcon icon={faParagraph} />
                <DropdownItemText>Normal</DropdownItemText>
                {blockType === 'paragraph' && <DropdownItemActive />}
              </Menu.Item>
              <Menu.Item className={dropdownItem} onClick={formatLargeHeading}>
                <DropdownItemIcon icon={faHeading} />
                <DropdownItemText>Large Heading</DropdownItemText>
                {blockType === 'h2' && <DropdownItemActive />}
              </Menu.Item>
              <Menu.Item className={dropdownItem} onClick={formatSmallHeading}>
                <DropdownItemIcon icon={faHeading} />
                <DropdownItemText>Small Heading</DropdownItemText>
                {blockType === 'h3' && <DropdownItemActive />}
              </Menu.Item>
            </Menu.Popup>
          </Menu.Positioner>
        </Menu.Portal>
      </div>
      <button
        onClick={formatBulletList}
        className={toolbarItemButtonRecipe({ spaced: true, active: blockType === 'ul' })}
        aria-label="Bulleted List"
        type="button"
      >
        <FontAwesomeIcon className={toolbarItemFormat} icon={faListUl} fixedWidth />
      </button>
      <button
        onClick={formatNumberedList}
        className={toolbarItemButtonRecipe({ spaced: true, active: blockType === 'ol' })}
        aria-label="Numbered List"
        type="button"
      >
        <FontAwesomeIcon className={toolbarItemFormat} icon={faListOl} fixedWidth />
      </button>
      <button
        onClick={formatQuote}
        className={toolbarItemButtonRecipe({ spaced: true, active: blockType === 'quote' })}
        aria-label="Quote"
        type="button"
      >
        <FontAwesomeIcon className={toolbarItemFormat} icon={faQuoteRight} fixedWidth />
      </button>
    </Menu.Root>
  );
};

export default BlockOptionsToolbar;
