import { faVimeo, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCloud, faEnvelopeSquare, faPhotoVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@base-ui-components/react/menu';
import { LexicalEditor } from 'lexical';
import React, { useState } from 'react';
import GenericReactModal from 'web/components/GenericReactModal';
import sc from 'web/components/styled';
import { DropdownItemIcon, DropdownItemText } from './Dropdown';
import { dropdown, dropdownItem } from './dropdown.css';
import InsertCloudAppDialog from './InsertCloudAppDialog';
import InsertSendsparkDialog from './InsertSendsparkDialog';
import InsertVimeoDialog from './InsertVimeoDialog';
import InsertYouTubeDialog from './InsertYouTubeDialog';
import { toolbarItemButton, toolbarItemIcon } from './toolbar.css';

const DialogContainer = sc.div(undefined, { width: 300, maxWidth: '95vw' });

const InsertVideoToolbar = ({ editor }: { editor: LexicalEditor }) => {
  const [openModal, setOpenModal] = useState<'none' | 'youtube' | 'cloudapp' | 'vimeo' | 'sendspark'>('none');
  return (
    <>
      <Menu.Root modal={false}>
        <div>
          <Menu.Trigger className={toolbarItemButton} aria-label="Insert media" title="Insert media">
            <FontAwesomeIcon className={toolbarItemIcon} icon={faPhotoVideo} fixedWidth />
          </Menu.Trigger>
          <Menu.Portal>
            <Menu.Positioner positionMethod="fixed">
              <Menu.Popup className={dropdown}>
                <Menu.Item className={dropdownItem} onClick={() => setOpenModal('youtube')}>
                  <DropdownItemIcon icon={faYoutube} />
                  <DropdownItemText>YouTube</DropdownItemText>
                </Menu.Item>
                <Menu.Item className={dropdownItem} onClick={() => setOpenModal('vimeo')}>
                  <DropdownItemIcon icon={faVimeo} />
                  <DropdownItemText>Vimeo</DropdownItemText>
                </Menu.Item>
                <Menu.Item className={dropdownItem} onClick={() => setOpenModal('cloudapp')}>
                  <DropdownItemIcon icon={faCloud} />
                  <DropdownItemText>CloudApp</DropdownItemText>
                </Menu.Item>
                <Menu.Item className={dropdownItem} onClick={() => setOpenModal('sendspark')}>
                  <DropdownItemIcon icon={faEnvelopeSquare} />
                  <DropdownItemText>Sendspark</DropdownItemText>
                </Menu.Item>
              </Menu.Popup>
            </Menu.Positioner>
          </Menu.Portal>
        </div>
      </Menu.Root>
      <GenericReactModal isOpen={openModal !== 'none'} onRequestClose={() => setOpenModal('none')}>
        <DialogContainer>
          {openModal === 'youtube' && (
            <InsertYouTubeDialog activeEditor={editor} onClose={() => setOpenModal('none')} />
          )}
          {openModal === 'cloudapp' && (
            <InsertCloudAppDialog activeEditor={editor} onClose={() => setOpenModal('none')} />
          )}
          {openModal === 'vimeo' && <InsertVimeoDialog activeEditor={editor} onClose={() => setOpenModal('none')} />}
          {openModal === 'sendspark' && (
            <InsertSendsparkDialog activeEditor={editor} onClose={() => setOpenModal('none')} />
          )}
        </DialogContainer>
      </GenericReactModal>
    </>
  );
};

export default InsertVideoToolbar;
